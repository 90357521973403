<template>
  <div class="mt-9">
    <v-col 
      cols="12"
      md="12"
      lg="12"
      class="mx-auto">
      <v-card>
        <v-card-title class="justify-center text-h5">
          {{ $t('Políticas de Privacidad') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{$t('Política de privacidad de la plataforma web Instrumentos de Evaluación de Indicadores de Calidad Perkins.')}}
          {{ $t('En Perkins - School for the Blind valoramos y respetamos la privacidad de nuestros usuarios. La siguiente política de privacidad describe cómo recopilamos, usamos y protegemos la información personal que se nos proporciona a través de la plataforma web de evaluaciones de colegios.') }}
        </v-card-text>
      </v-card>
      <v-card class="mt-6">
        <v-card-title>
          {{ $t('Información recopilada') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('Para utilizar nuestra plataforma web, se requiere que los usuarios proporcionen cierta información personal, como nombre, dirección de correo electrónico, cargo y país.') }}
        </v-card-text>
      </v-card>
      <v-card class="mt-6">
        <v-card-title>
          {{ $t('Uso de la información') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{$t('Utilizamos la información personal para ofrecer a los usuarios acceso a la plataforma web de evaluaciones de colegios y para comunicarnos con ellos en relación con el uso de la plataforma. También podemos utilizar la información para mejorar nuestros servicios, para desarrollar nuevos productos y servicios y para proporcionar información y ofertas que creemos que puedan ser de interés para los usuarios.')}}<br>
          {{ $t('No vendemos ni alquilamos información personal a terceros. Sin embargo, podemos compartir información personal con empresas que prestan servicios en nuestro nombre, como proveedores de alojamiento web y proveedores de servicios de correo electrónico.') }}
        </v-card-text>
      </v-card>
      <v-card class="mt-6">
        <v-card-title>
          {{ $t('Protección de la información') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('Perkins - School for the Blind se compromete a proteger la información personal de nuestros usuarios. Utilizamos medidas de seguridad razonables para proteger la información personal contra el acceso no autorizado, la divulgación o el uso indebido. También requerimos que los proveedores de servicios que procesan información personal en nuestro nombre utilicen medidas de seguridad adecuadas para proteger la información.') }}
        </v-card-text>
      </v-card>
      <v-card class="mt-6">
        <v-card-title>
          {{ $t('Cookies') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('Utilizamos cookies en nuestra plataforma web de evaluaciones de colegios para recordar las preferencias de los usuarios, para mejorar la experiencia de usuario y para recopilar información de uso. Los usuarios pueden controlar el uso de cookies a través de la configuración de su navegador web.') }}
        </v-card-text>
      </v-card>
      <v-card class="mt-6">
        <v-card-title>
          {{ $t('Enlaces a sitios web de terceros') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('Nuestra plataforma web de evaluaciones de colegios puede contener enlaces a sitios web de terceros que no están bajo nuestro control. No somos responsables de las prácticas de privacidad o del contenido de estos sitios web. Recomendamos a los usuarios que revisen las políticas de privacidad de estos sitios web antes de proporcionar información personal.') }}
        </v-card-text>
      </v-card>
      <v-card class="mt-6">
        <v-card-title>
          {{ $t('Cambios en la política de privacidad') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('Nos reservamos el derecho de actualizar o modificar esta política de privacidad en cualquier momento. Los cambios entrarán en vigor a partir de la fecha de su publicación en nuestra plataforma web de evaluaciones de colegios. Al utilizar nuestra plataforma web después de la publicación de cambios en esta política de privacidad, los usuarios aceptan los términos de la política de privacidad modificada.') }}
        </v-card-text>
      </v-card>
      <v-card class="mt-6">
        <v-card-subtitle>{{ $t('Fecha de entrada en vigor: 1 de Mayo de 2023.') }}</v-card-subtitle>
      </v-card>
    </v-col>
  </div>
</template>

<script>
export default {
  data: () => {
    return {

    }
  },
  methods: {
    scrollTo() {
      this.$refs.section.scrollIntoView({ behavior: "smooth" })
    }
  }
}
</script>